.change-payment-method {
  padding: 40px 0px 105px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &-title {
    color: #fff;
    text-align: left;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
  &-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 52px;
  }

  .back-btn {
    position: absolute;
    left: 0;
    top: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e1e1e;
    cursor: pointer;
  }
}
