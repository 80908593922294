.qrcode-container {
  .nav-menu__navigation {
    background: #1e1e1e !important;
  }
  .title {
    padding: 0px 20px;
    margin-bottom: 57px;
  }
  .qr-code {
    width: 100%;
    border-radius: 50px;
  }
}
