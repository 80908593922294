@mixin font-face(
  $font-family,
  $file-path,
  $font-weight: 'normal',
  $font-style: 'normal'
) {
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}.ttf');
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
  }
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/Inter/Inter-Regular.woff') format('woff');
}
//font weight 400
@include font-face('Inter', '../fonts/Inter/Inter-Regular', 400);
@include font-face('Inter-Bold', '../fonts/Inter/Inter-SemiBold', 600);
