.profile-container {
  .title {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }
  .form-conatiner {
    padding-bottom: 105px;
  }
  form {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    min-height: max-content;
    align-items: center;
  }
  .card-details {
    display: flex;
    align-items: center;
    gap: 14px;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    svg {
      min-width: 25px;
    }
  }
  .action-info {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 14px;
  }
  .change-btn {
    display: flex;
    align-items: center;
    gap: 14px;
    width: 100%;
    height: 54px;
    border-radius: 10px;
    border: 2px solid #1e1e1e;
    padding: 14px 20px;
    margin-bottom: 12px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.change-payment {
      flex-direction: column;
      height: fit-content;
    }
  }
  .text-btn {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 14px;
  }
  .cancel-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    width: 100%;
    height: auto;
    border-radius: 10px;
    border: 2px solid #1e1e1e;
    padding: 14px 20px;
    margin-top: -18px;
    margin-bottom: 30px;

    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media only screen and (max-width: 770px) {
      flex-direction: column;
    }
    .linear-gradient-button {
      max-width: 30%;
      margin-left: 20px;
      @media only screen and (max-width: 770px) {
        max-width: 100%;
        margin-left: 0;
      }
    }
    img {
      max-height: 24px;
      width: auto;
    }
    span {
      display: inline;
      .link {
        cursor: pointer;
        font-family: "Inter-Bold" !important;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
  .profile-separator {
    width: calc(100% + 40px);
    margin-left: -20px;
    display: block;
    border-bottom: 1px solid #1e1e1e;
  }
  .privacy-policy {
    display: block;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-decoration: none;
    margin-bottom: 12px;
    width: max-content;
  }
  .terms {
    display: block;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-decoration: none;
    width: max-content;
  }
  .profile-submit-container {
    margin: 30px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .logout-btn {
    width: fit-content;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    margin: 30px 0 10px;
  }
  .bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.unsubscribe-btn {
  cursor: pointer;
  color: #5dc7df;
  background: transparent;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
