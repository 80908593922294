.sign-up-container {
  .signupbgimg {
    position: absolute;
    right: 0px;
    top: -105px;
    z-index: -1;
  }

  .terms-conditions {
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    .custom-checkbox {
      display: block;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      color: #5e5e5e;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      a {
        text-decoration-line: underline;
        color: #5e5e5e;
      }
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: #111;
        border-radius: 4px;
        border: 2px solid #5e5e5e;
      }
    }
    .custom-checkbox:hover input ~ .checkmark {
      background-color: #111;
    }
    .custom-checkbox input:checked ~ .checkmark {
      background: var(
        --Linear,
        linear-gradient(270deg, #d2e780 0%, #5dc7df 100%)
      );
    }
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    .custom-checkbox input:checked ~ .checkmark:after {
      display: block;
    }
    .custom-checkbox .checkmark:after {
      left: 7px;
      top: 2px;
      width: 7px;
      height: 12px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  .privacy-policy {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    a {
      text-decoration: underline;
      color: #fff;
    }
  }
}
.Toastify__toast {
  width: 335px;
  min-height: max-content;
  max-height: inherit !important;
  height: auto;
  border-radius: 50px !important;
  background: #1e1e1e !important;
}
.Toastify__toast-body {
  padding: 4px !important;
}

.Toastify__close-button {
  display: none !important;
}
.Toastify__toast-icon {
  display: none !important;
}
@media only screen and (max-width: 480px) {
  .Toastify__toast-container > .Toastify__toast {
    margin-top: 25px !important;
    left: calc(50% - 167px);
    max-width: 100%;
  }
}
