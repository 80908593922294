.welcome {
    padding-top: 60px;
    padding-bottom: 90px;
    h1 {
        color: #fff;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
    }
    p {
        color: #777;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 20px;
    }
    .btns-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 21px;
        a {
            display: flex;
            padding: 12px 0px;
            justify-content: center;
            align-items: center;
            width: 155px;
            border-radius: 12px;
            border: 1px solid #f7fac2;
            color: #fff;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: none;
        }
    }
}
