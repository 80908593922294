.notification-btns {
    display: flex;
    align-items: center;
    gap: 12px;
    .notification-item {
        border-radius: 10px;
        background: #1e1e1e;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.notifications-container {
    position: fixed;
    top: 0px;
    left: 0px;
    background: #1e1e1e;
    width: 100%;
    height: 100vh;
    overflow: auto;
    padding-bottom: 40px;
    .notifications-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 22px 22px;
        .clear-btn {
            color: #fff;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 185.714% */
            text-decoration-line: underline;
        }
    }
    .notification-title {
        text-align: center;
        color: #777;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
    }
    .notifications-list {
        li {
            border-bottom: 2px solid #434242;
            padding: 13px 22px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            &:first-of-type {
                border-top: 2px solid #434242;
            }
            .notify-value-container {
                background: #111;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                .notify-value {
                    font-size: 12px;
                    background: var(
                        --Linear,
                        linear-gradient(270deg, #d2e780 0%, #5dc7df 100%)
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                &.negative {
                    background: #fff;
                    .notify-value {
                        font-size: 12px;
                        background: transparent !important;
                        color: #5b22eb;
                        -webkit-text-fill-color: unset;
                    }
                }
            }
        }
    }
}
