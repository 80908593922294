.update-message-container {
  padding: 40px 0px 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .back-btn {
    position: absolute;
    left: 0;
    top: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e1e1e;
  }
  .btn-section {
    margin-top: 20px;
  }
  .round-message-value {
    width: 183.32px;
    height: 183.32px;
    border-radius: 50%;
    background-color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 13px;
    .bgmessage {
      position: absolute;
      object-fit: cover;
    }
    span {
      display: block;
      color: #60c8dd;
      text-align: center;
      font-size: 61.107px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      position: relative;
    }
  }
  .koin-message-text {
    color: #fff;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
    p {
      color: #777;
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .koin-message-title {
    color: #fff;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .koin-message-text {
    color: #777;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-top: 10px;
  }
}
