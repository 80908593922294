.payment-form {
  background: #111111;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;

  &.update-payment-method {
    position: relative;
    padding-top: 20px;

    .payment-form-content {
      display: flex;
      align-items: flex-start;

      #rswp-card-button {
        position: relative;

        &:before {
          position: absolute;
          content: "Update";
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 0;
          top: 0;
          border-radius: 5px;
          background: var(
            --Linear,
            linear-gradient(270deg, #d2e780 0%, #5dc7df 100%)
          );
        }
      }
    }
  }

  &-content {
    align-items: center;
    display: grid;
    height: 100vh;
    justify-content: center;

    #rswp-card-button {
      background: var(
        --Linear,
        linear-gradient(270deg, #d2e780 0%, #5dc7df 100%)
      );
    }

    .sq-focus {
      .sq-card-iframe-container {
        &::before {
          border: 1px solid #63c9da !important;
        }
      }

      .sq-card-message {
        color: white;
        height: 16px !important;

        &:before {
          display: none;
        }
      }
    }
  }
}
