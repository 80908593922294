@import "/src/assets/styles/variables";

.terms-page {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 70px 20px 105px;

  .back-btn {
    position: absolute;
    left: 20px;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e1e1e;
    cursor: pointer;
  }

  .last-updated {
    font-size: 10pt;
  }

  .email {
    text-decoration: none !important;
  }

  .pt-3 {
    padding-top: 3pt;
  }

  .pl-5-left {
    padding-left: 5pt;
    text-align: left;
  }
  .pl-5 {
    padding-left: 5pt;
    text-align: justify;
  }
  .decoration-none {
    text-decoration: none;
  }
  .pt-11 {
    padding-top: 11pt;
  }
  .ordered-li {
    padding-left: 15pt;
    margin-left: 15pt;
    line-height: 11pt;
    text-align: justify;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  h1 {
    color: white;
    font-style: normal;
    font-family: $mainBoldFont !important;
    text-decoration: none;
    font-size: 12.5pt;
  }

  .p,
  p {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9.5pt;
    margin: 0pt;
    position: relative;
    line-height: 110%;

    b {
      font-family: $mainBoldFont;
    }
  }

  .s1 {
    color: white;
    font-style: normal;
    font-family: $mainBoldFont !important;
    text-decoration: none;
    font-size: 9.5pt;
    span {
      color: white;
      font-style: normal;
      font-family: $mainBoldFont !important;
      text-decoration: none;
      font-size: 9.5pt;
    }
  }

  h2 {
    color: white;
    font-style: normal;
    font-family: $mainBoldFont !important;
    text-decoration: underline;
    font-size: 9.5pt;
    line-height: 110%;
  }

  .s2 {
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    color: white;
  }

  .s3 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9.5pt;
  }

  h3 {
    color: white;
    font-style: normal;
    font-family: $mainBoldFont !important;
    text-decoration: none;
    font-size: 7.5pt;
  }

  .s4 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
  }

  .s5 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
  }

  .s8 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 7.5pt;
  }

  .s9 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
  }

  .s10 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
  }

  .s11 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
  }

  li {
    display: block;
  }

  #l1 {
    padding-left: 0pt;
    counter-reset: c1 1;
  }

  #l1 > li > *:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal) ". ";

    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9.5pt;
    position: absolute;
    left: 0;
  }

  #l1 > li:first-child > *:first-child:before {
    counter-increment: c1 0;
  }

  .l2 {
    padding-left: 0pt;
    counter-reset: c2 1;
  }
  .l2 > li > *:first-child:before {
    counter-increment: c2;
    content: counter(c2, lower-latin) ") ";

    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9.5pt;
    line-height: 9.5pt;
    position: absolute;
    left: 0;
  }
  .l2 > li:first-child > *:first-child:before {
    counter-increment: c2 0;
  }
  ul,
  ol {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .a {
    text-decoration: underline;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    cursor: pointer;
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }

  .email {
    text-decoration: underline !important;
  }
}
