.change-password {
  &-title {
    color: #fff;
    text-align: left;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
  &-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 52px;
  }
}
.change-password-container {
  padding: 40px 0px 105px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .back-btn {
    position: absolute;
    left: 0;
    top: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e1e1e;
    cursor: pointer;
  }
  .title {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }
  .form-conatiner {
    width: 100%;
    padding-top: 40px;
  }
  form {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    min-height: max-content;
    align-items: center;
  }
  .change-btn {
    display: flex;
    align-items: center;
    gap: 14px;
    width: 100%;
    height: 54px;
    border-radius: 10px;
    border: 2px solid #1e1e1e;
    padding: 14px 20px;
    margin-bottom: 12px;

    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .profile-separator {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: 30px;
    display: block;
    border-bottom: 1px solid #1e1e1e;
  }
  .privacy-policy {
    display: block;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-decoration: none;
    margin-bottom: 12px;
    width: max-content;
  }
  .terms {
    display: block;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-decoration: none;
    width: max-content;
  }
  .profile-submit-container {
    margin: 30px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .logout-btn {
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    margin: 30px 0;
  }
  .bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.unsubscribe-btn {
  cursor: pointer;
  color: #5dc7df;
  background: transparent;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
