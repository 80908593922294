.confirm-email-container {
  padding-top: 80px;
  width: 100%;
  position: relative;
  height: calc(100vh - 80px);
  .confirm-bg {
    position: absolute;
    right: -18px;
    bottom: 0;
    z-index: -1;
    width: auto;
    height: 100vh;
  }
  .confirm-email-title {
    color: #fff;
    text-align: left;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 29px;
    width: 100%;
  }
  .confirm-email-description {
    color: #777;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    width: 100%;
  }
}
