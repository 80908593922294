@use "sass:math";
// Libraries
@import "libs/reset";

// Abstracts
@import "fonts";
@import "variables";

// General
@import "general-classes";
@import "buttons";

//Pages
@import "../../containers/Home/Home.scss";
@import "../../containers/Login/Login.scss";
@import "../../containers/ForgotPassword/RessetPassword.scss";
@import "../../containers/SignUp/SignUp.scss";
@import "../../containers/ChangePaymentMethod/ChangePaymentMethod.scss";

//Components
@import "../../components/Header/Header";
@import "../../components/Welcome/Welcome";
@import "../../components/NotificationsBtns/NotificationsBtns";
@import "../../components/Introbutons/Introbutons";
@import "../../components/BottomNavigation/BottomNavigation";

/* Global styles */
body {
  background-color: $black-Main;
  font-family: $mainFont !important;
}

div,
label,
span,
h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: $mainFont !important;
}
