.warning-container {
    &.full-container {
        height: 100vh !important;
        padding: 121px 0px;
    }
    .warning-block {
        border-radius: 50px;
        background: #111;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 20px;
        margin-bottom: 29px;
        img {
            margin-bottom: 20px;
        }
        .title {
            margin-bottom: 12px;
        }
        p {
            color: #777;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }
    }
    .btns-container {
        padding: 0px 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        .linear-gradient-button .submit-button {
            background: #1e1e1e;
        }
        .linear-gradient-button:first-of-type {
            .submit-button {
                flex-direction: row-reverse;
            }
            img {
                transform: rotate(180deg);
            }
        }
    }
}
