.send-koins-container {
  &.full-container {
    height: 150vh;
    overflow: hidden;
    position: fixed;
    bottom: 50vh;
  }
  .send-koins-header {
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 57px;
    .title {
      margin-bottom: 0px;
    }
  }
  .close {
    cursor: pointer;
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  .name {
    color: #fff;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 20px 20px;
  }
  .input-wrapper-container {
    width: 100%;
    border-radius: 32px;
    background-color: #fff;
    .input-wrapper {
      padding: 40px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .minus,
      .plus {
        cursor: pointer;
        width: 50px;
        height: 50px;
        background-color: #f4f3f3;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .value {
        display: flex;
        flex-direction: column;
        align-items: center;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        input {
          max-width: 200px;
          width: 100%;
          text-align: center;
          color: #000;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        p {
          color: #777;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
        }
      }
    }
    .line-trough {
      width: 100%;
      height: 1px;
      background: #1e1e1e;
    }
    .input-email-container {
      padding: 20px;
      .input-email-wrapper {
        display: flex;
        align-items: center;
        gap: 14px;
        padding: 14px 20px;
        border-radius: 16px;
        background: #f4f3f3;
        input {
          background-color: transparent;
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          min-width: calc(100% - 50px);
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-background-clip: text;
          -webkit-text-fill-color: #000;
          transition: background-color 5000s ease-in-out 0s;
          box-shadow: inset 0 0 20px 20px #f4f3f3;
        }
      }
      ::placeholder {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }
    }
  }
  .btn-container {
    padding: 40px 20px 20px;
  }
}
