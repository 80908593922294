.koin-purse-container {
  padding: 40px 0px 105px;
  .title {
    margin-bottom: 12px;
  }

  .email-profile {
    color: #777;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 40px;
    display: block;
  }
  .wrapper-coin {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    height: 96px;
    background: var(
      --Linear,
      linear-gradient(270deg, #d2e780 0%, #5dc7df 100%)
    );
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
    .bgwrappcoin {
      position: absolute;
      left: 0px;
      top: 0px;
      max-width: 100%;
    }
    .balance-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 4px;
      justify-content: flex-start;
      height: 100%;
      .balance-value {
        color: #000;
        text-align: right;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .balance-title {
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
      .balance-text {
        color: #777;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
  .koin-btns-container {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    margin-bottom: 35px;
    .btn-item {
      cursor: pointer;
      padding: 15px 8px 15px 12px;
      width: 100%;
      border-radius: 10px;
      background: #1e1e1e;
      display: flex;
      align-items: center;
      gap: 6px;
      color: #777;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .privacy-policy {
    display: block;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-decoration: none;
    margin-bottom: 12px;
    width: max-content;
  }
  .terms {
    display: block;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-decoration: none;
    width: max-content;
  }
}
