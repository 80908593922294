.unsubscribe-reasons-container {
  padding: 40px 0px 105px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .back-btn {
    position: absolute;
    left: 0;
    top: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e1e1e;
    cursor: pointer;
  }
  .round-message-value {
    width: 176px;
    height: 176px;
    border-radius: 50%;
    background-color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    .bgmessage {
      position: absolute;
      object-fit: cover;
    }
    span {
      display: block;
      color: #60c8dd;
      text-align: center;
      font-size: 61.107px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      position: relative;
    }
  }
  .other-textarea {
    width: 100%;
    position: relative;
    margin-top: 9px;

    &::before {
      position: absolute;
      content: '';
      top: 15px;
      left: 20px;
      width: 24px;
      height: 24px;
      background: url(../../assets/svg/smiley-icon.svg);
      background-size: 24px 24px;
      background-position: center;
      z-index: 2;
    }
    .wrapper {
      max-width: 377px;
      border-radius: 16px;
      height: max-content;
      overflow: hidden;
    }
    textarea {
      width: 100%;
      max-width: 377px;
      min-height: 54px;
      height: auto;
      display: block;
      border-radius: 16px;
      background: #1e1e1e;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      padding: 14px 10px 14px 58px;
      color: white;
      resize: none;
      &::-webkit-scrollbar {
        width: 12px; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #1e1e1e;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ffffff6f; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 3px solid #1e1e1e;
      }
    }
  }
  .unsubscribe-message {
    &-title {
      color: #fff;
      text-align: left;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 10px;
    }
    &-text {
      color: #777;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 40px;
    }
    &-separator {
      width: calc(100% + 40px);
      margin-left: -20px;
      display: block;
      border-bottom: 1px solid #1e1e1e;
    }
    &-top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 52px;
    }
    &-bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      margin: 40px 0;
    }
    &__option {
      position: relative;
      padding-left: 29px;
      cursor: pointer;
      color: #777;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      input {
        opacity: 0;
        z-index: 2;
        cursor: pointer;
        position: absolute;
        top: 4px;
        left: 0;
        width: 17px;
        height: 17px;
        margin: 0;

        & + .unsubscribe-message__label {
          display: inline-flex;
          align-items: center;
          user-select: none;
          position: absolute;
          z-index: 0;
          top: 4px;
          left: 0;
          width: 17px;
          height: 17px;
        }
        & + .unsubscribe-message__label::before {
          content: '';
          display: inline-block;
          width: 17px;
          height: 17px;
          flex-shrink: 0;
          flex-grow: 0;
          border: 2px solid #cee683;
          border-radius: 50%;
          margin-right: 0.5em;
        }
        &.checked + .unsubscribe-message__label::before {
          border-color: #cee683;
          background-color: #cee683;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDQuNUwzLjI5Mjg5IDYuNzkyODlDMy42ODM0MiA3LjE4MzQyIDQuMzE2NTggNy4xODM0MiA0LjcwNzExIDYuNzkyODlMMTAuNSAxIiBzdHJva2U9IiMxMTExMTEiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=);
        }
        &.checked + .unsubscribe-message__label + span {
          color: #fff !important;
        }
      }
    }
    &__radio {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;
    }
  }
  .btn-section {
    width: 100%;
    .continue-btn {
      width: 100%;
      border: 2px solid transparent;
      background: linear-gradient(#111 0 0) padding-box,
        /*this is your grey background*/
          linear-gradient(to left, #d2e780, #5dc7df) border-box;
      border-radius: 12px;
      height: 54px;
      cursor: pointer;
    }
  }
}
