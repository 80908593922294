.confirm-code-container {
  padding: 40px 0px 105px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .back-btn {
    position: absolute;
    left: 0;
    top: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e1e1e;
    cursor: pointer;
  }
  .round-message-value {
    width: 176px;
    height: 176px;
    border-radius: 50%;
    background-color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    .bgmessage {
      position: absolute;
      object-fit: cover;
    }
    span {
      display: block;
      color: #60c8dd;
      text-align: center;
      font-size: 61.107px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      position: relative;
    }
  }

  .confirm-code-message {
    &-title {
      color: #fff;
      text-align: left;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 10px;
    }
    &-text {
      color: #777;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 40px;
    }
    &-separator {
      width: calc(100% + 40px);
      margin-left: -20px;
      display: block;
      border-bottom: 1px solid #1e1e1e;
    }
    &-top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 52px;
    }
    &-bottom {
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: flex-start;
      margin: 40px 0;
      justify-content: center;
      .inputs-field {
        width: 37px;
        padding: 7px 10px;
        border-radius: 10px;
        font-weight: 700;
        border: 2px solid transparent;
        background: linear-gradient(#111 0 0) padding-box,
          /*this is your grey background*/
            linear-gradient(to left, #d2e780, #5dc7df) border-box;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
    }
  }
  .unsubscribe-btn {
    margin-top: 20px;
    width: 100%;
  }
  .btn-section {
    width: 100%;
    .continue-btn {
      width: 100%;
      border: 2px solid transparent;
      background: linear-gradient(#111 0 0) padding-box,
        /*this is your grey background*/
          linear-gradient(to left, #d2e780, #5dc7df) border-box;
      border-radius: 12px;
      height: 54px;
      cursor: pointer;
    }
  }
}
