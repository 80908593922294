.homepage {
  position: relative;
  &.auth {
    padding-bottom: 105px;
  }
  .homebgimg {
    position: absolute;
    right: -18px;
    top: -100px;
    z-index: -1;
    width: 375px;
    height: auto;
  }
}
