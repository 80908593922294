.koin-message-container {
    padding: 40px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    &.add-koin {
        .round-message-value {
            span {
                color: #c2e38d;
            }
        }
    }
    .back-btn {
        position: absolute;
        left: 0;
        top: 40px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1e1e1e;
    }
    .round-message-value {
        width: 183.32px;
        height: 183.32px;
        border-radius: 50%;
        background-color: #1e1e1e;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        margin-bottom: 13px;
        .bgmessage {
            position: absolute;
            object-fit: cover;
        }
        span {
            display: block;
            color: #60c8dd;
            text-align: center;
            font-size: 61.107px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            position: relative;
        }
    }
    .koin-message-text {
        color: #fff;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
    }
}
