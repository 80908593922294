.login-container {
  .loginbgimg {
    position: absolute;
    right: 0px;
    top: -61px;
    z-index: -1;
    width: 375px;
    height: inherit;
    object-fit: unset;
    max-height: 614px;
  }
}
.forgot-password {
  display: block;
  text-align: center;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.error {
  border: 2px solid #fcc0c0;
}
