.resset-pass-container {
  .title {
    text-align: center;
    margin-bottom: 101px;
  }
  .forgotbgimg {
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: -1;
  }
  .progress-line {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;
    margin-top: 38px;
    .line {
      height: 3px;
      width: 33.333%;
      background: #1e1e1e;
      &.active {
        background: var(
          --Linear,
          linear-gradient(270deg, #d2e780 0%, #5dc7df 100%)
        );
      }
    }
  }
}
