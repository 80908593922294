@import '/src/assets/styles/variables';

.privacy-policy-page {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 20px 20px 105px;

  .back-btn {
    position: absolute;
    left: 20px;
    top: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e1e1e;
    cursor: pointer;
  }
  .title {
    padding: 3pt 40px 0;
    text-align: center;
    margin-bottom: 10px;
  }

  .date {
    padding-top: 4pt;
    padding-left: 5pt;
    text-align: left;
  }

  .pl-5 {
    padding-left: 5pt;
    text-align: justify;
  }

  .p-wrapp {
    display: flex;
    flex-direction: column;
    gap: 7px;

    @media screen and (max-width: 350px) {
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
    }
  }

  .p-intern {
    text-align: center;
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 350px) {
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      gap: 15px;
    }
  }

  .justify {
    text-align: justify;
  }

  .a-center {
    align-items: center;
    @media screen and (max-width: 350px) {
      align-items: flex-start;
    }
  }

  .pl-5-left {
    padding-left: 5pt;
    text-align: left;
  }

  .pt-5 {
    padding-top: 5pt;
  }

  .li {
    padding-left: 15pt;
    margin-left: 15pt;
    line-height: 110%;
    text-align: left;
  }

  .unordered-li {
    padding-left: 24pt;
    margin-left: 14pt;
    text-align: left;
  }

  .l2-li {
    padding-left: 16pt;
    text-indent: 16pt;
    line-height: 110%;
    text-align: left;
  }

  h1 {
    color: white;
    font-style: normal;
    font-family: $mainBoldFont !important;
    text-decoration: none;
    font-size: 12.5pt;
  }

  .s-1 {
    font-family: $mainBoldFont !important;

    span {
      font-family: $mainBoldFont !important;
    }
  }
  .p,
  p {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9.5pt;
    margin: 0pt;
    position: relative;
    line-height: 110%;
    text-align: justify !important;
  }

  .s1 {
    color: white;
    font-style: normal;
    font-family: $mainBoldFont !important;
    text-decoration: none;
    font-size: 9.5pt;
    span {
      color: white;
      font-style: normal;
      font-family: $mainBoldFont !important;
      text-decoration: none;
      font-size: 9.5pt;
    }
  }

  h2 {
    color: white;
    font-style: normal;
    font-family: $mainBoldFont !important;
    text-decoration: underline;
    font-size: 9.5pt;
  }

  .s2 {
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
  }

  .s3 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9.5pt;
  }

  h3 {
    color: white;
    font-style: normal;
    font-family: $mainBoldFont !important;
    text-decoration: none;
    font-size: 7.5pt;
  }

  .s4 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
  }

  .s5 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 7.5pt;
  }

  .a {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 7.5pt;
  }

  .s8 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
    display: inline;
  }

  .s9 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
  }

  .s10 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
  }

  .s11 {
    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7.5pt;
  }

  li {
    display: block;
  }

  #l1 {
    padding-left: 0pt;
    counter-reset: c1 1;
  }

  #l1 > li > *:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal) '. ';

    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 9.5pt;
    position: absolute;
    left: 0;
  }

  #l1 > li:first-child > *:first-child:before {
    counter-increment: c1 0;
  }

  #l2 {
    padding-left: 0pt;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1px;
  }

  #l2 > li > *:first-child:before {
    content: '● ';

    color: white;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 6.5pt;
    line-height: 9.5pt;
    position: absolute;
    left: 0;
  }
  ul,
  ol {
    display: flex;
    flex-direction: column;
  }
  a {
    text-decoration: underline;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
