.linear-gradient-button {
  background: var(--Linear, linear-gradient(270deg, #d2e780 0%, #5dc7df 100%));
  border-radius: 12px;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  .submit-button {
    width: 99%;
    margin: 0 auto;
    height: 50px;
    border-radius: 12px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    background-color: $black-Main;
  }
  &:hover {
    .submit-button {
      background-color: transparent;
    }
  }
}
