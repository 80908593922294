.notifications-container {
  &.full-container {
    height: 100vh;
  }
  .notifications-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .title {
      margin-bottom: 0;
    }
  }
  .clear {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    margin: 7px 0;
    cursor: pointer;
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 22px;
    img {
      max-width: 40px;
      height: auto;
    }
    span {
      color: #777;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .notifications-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 2px solid #434242;
  }
  .notifications-item {
    display: flex;
    justify-content: space-between;
    padding: 13px 20px 13px 22px;
    border-bottom: 2px solid #434242;
    cursor: pointer;
    &__email {
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
    &__value {
      width: 30px;
      height: 30px;
      background-color: #111;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      &.bigger span {
        background: -webkit-linear-gradient(270deg, #d2e780 0%, #5dc7df 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &.smaller {
        background: #fff;

        span {
          color: #5b22eb;
        }
      }
    }
  }
  .close {
    cursor: pointer;
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}
