.header {
  position: sticky;
  top: 0;
  z-index: 9;
  &.scrolled {
    background: #111;
  }
  .header-container {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-conatiner {
      display: flex;
      align-items: center;
      gap: 12px;
      color: #fff;
      font-family: Blinker;
      font-size: 18.635px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;
      max-width: 168px;
      img {
        width: 100%;
      }
    }
    .btn-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }
    .loginbtn {
      width: 111px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 10px;
      background: var(
        --Linear,
        linear-gradient(270deg, #d2e780 0%, #5dc7df 100%)
      );
      color: #111;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      border: none;
      text-decoration: none;
    }
    .authbtn {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      background: #1e1e1e;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .badge {
        background: #cd3232;
        border-radius: 50%;
        color: white;
        font-size: 10px;
        position: absolute;
        padding: 5px;
        top: -8px;
        right: -10px;
        width: 20px;
        text-align: center;
      }
    }
  }
}
