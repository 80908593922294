.nav-menu__navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  position: fixed;
  bottom: -1px;
  width: 100%;
  left: 0;
  padding: 20px;
  background-color: #111;
  &.spacing {
    justify-content: space-evenly;
  }
  &.gray-bg {
    background: #1e1e1e !important;
    z-index: 9;
  }
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    color: #777;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    .active-img {
      display: none;
    }
    &.active {
      color: #60c8dd;
      img {
        display: none;
      }
      .active-img {
        display: block;
      }
    }
  }
}
