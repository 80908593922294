/**
 * Variables
 */

// ============================================================================= Breakpoints
//Media Queries
$largeDesktopLg: '(min-width: 1500px)';
$desktopLg: '(max-width: 1499px)';
$largeDesktop: '(min-width: 1301px)';
$desktopMd: '(max-width: 1300px)';
$desktop: '(min-width: 1200px)';
$desktopSmall: '(max-width: 1199px)';
$desktopSm: '(min-width: 992px) and (max-width: 1199px)';
$onlyDesktop: '(min-width: 992px)';

$maxTablet: '(max-width: 991px)';
$tablet: '(min-width: 768px) and (max-width: 991px)';

$not-phone: '(min-width: 768px)';
$phone: '(max-width: 767px)';
$phoneSm: '(max-width: 450px)';

// ============================================================================= Colors
// background-color: palette(main, base);
$main-color: #fff;
$black-Main: #111;
$gray: #777;
// ============================================================================= Fonts

$mainFont: 'Inter', sans-serif;
$mainBoldFont: 'Inter-Bold', sans-serif;
