input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #1e1e1e;
}
.title {
  margin-bottom: 30px;
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.form-conatiner {
  padding-top: 60px;
  padding-bottom: 0;
}
form {
  margin-bottom: 30px;
}
.inputs-field:last-of-type {
  margin-bottom: 30px;
}
.inputs-field:not(:has(~ .inputs-field)) {
  margin-bottom: 30px;
}
.inputs-field {
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
  height: 54px;
  border-radius: 16px;
  background: #1e1e1e;
  padding: 14px 20px;
  margin-bottom: 12px;
  input {
    width: 100%;
    color: #fff;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    background: none;
    &:focus,
    &:active {
      outline: none;
      background: transparent !important;
    }
  }
  ::placeholder {
    color: #777;
  }
}
.copyright {
  padding-bottom: 100px;
  color: #777;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.full-container {
  position: absolute;
  padding-top: 16px;
  width: 100%;
  background: #1e1e1e;
  left: 0px;
  top: 0px;
  height: calc(100vh - 104px);
  overflow: auto;
  z-index: 9;
}
.continue-btn {
  border-radius: 12px;
  border: 1px solid #f7fac2;
  width: 124px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.PhoneInputCountrySelectArrow {
  border-color: white !important;
  opacity: 1 !important;
}
