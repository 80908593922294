.introbutons {
  padding-bottom: 40px;
  .introbtn {
    width: 100%;
    height: 80px;
    border-radius: 20px;
    background: #1e1e1e;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 19px;
    text-decoration: none;
    margin-bottom: 20px;
    padding: 0px 11px;
    box-sizing: border-box;
    &:last-of-type {
      margin-bottom: 0px;
    }
    img {
      max-width: 60px;
      width: 100%;
      height: auto;
    }
  }
}
